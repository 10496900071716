import { formatMoney } from "@api/money";
import { CFC } from "@api/types/cypress";
import Product from "@api/types/product";

import LinkButton from "@components/LinkButton";

const ProductCard: CFC<{ product: Product }> = ({ product, cy }) => {
  return (
    <>
      <div data-cy={cy} className="ProductCard">
        <img src={product.thumbnail.src} alt={product.title} />
        <div className="category">{product.category}</div>
        <div className="title">{product.title}</div>
        <div className="description" dangerouslySetInnerHTML={{ __html: product.description }} />
        <div className="actions">
          <div className="price">
            <span className="price-value">{formatMoney(product.defaultVariant.price)}</span>
            {` `}
            <span className="price-description">{product.defaultVariant.description}</span>
          </div>
          <div className="buttons">
            {/*<BuyButton product={product} />*/}
            <LinkButton href={`/course/id/${product.mpn}`} theme="secondary">
              Course Details
            </LinkButton>
          </div>
        </div>
      </div>
      {/* language=CSS */}
      <style jsx>
        {`
          .ProductCard {
            position: relative;
            padding-bottom: 120px;
          }

          .ProductCard img {
            width: 100%;
            max-width: 100%;
          }

          .category {
            margin: 16px 0;
            font-size: 24px;
            color: #94c1d0;
          }

          .title {
            margin: 16px 0;
            font-size: 30px;
            color: rgb(0, 0, 0);
          }

          .actions {
            position: absolute;
            bottom: 0;
            width: 100%;
          }

          .buttons {
            display: flex;
          }

          .actions-item {
            flex: 1;
          }

          .description {
            line-height: 26px;
            color: rgb(51, 51, 51);
          }

          .price {
            margin: 0 0 15px 0;
          }

          .price-value {
            color: #ff0000;
            font-size: 24px;
          }

          .price-description {
            color: rgb(0, 0, 0);
            font-size: 18px;
          }
        `}
      </style>
    </>
  );
};

export default ProductCard;
