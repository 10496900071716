const enrollIntoCourse = /* GraphQL */ `
  mutation enrollIntoCourse($input: CourseEnrollmentInput!) {
    enrollIntoCourse(input: $input) {
      id
      email
      customerId
    }
  }
`;

export default enrollIntoCourse;
