import enrollIntoCourse from "@api/operations/enroll-into-course";
import Course from "@api/types/course";
import Router from "next/router";
import React from "react";

import Button, { ButtonProps } from "@components/Button/Button";

const EnrollButton: React.FC<
  ButtonProps & {
    token: string;
    course: Course;
  }
> = (props) => {
  const { token, course } = props;

  return (
    <>
      {course.variantsEnrolledIn.filter((v) => v === "default").length === 0 ? (
        <Button
          {...props}
          onClick={async () => {
            const [{ id }] = await enrollIntoCourse(token, {
              course: course.courseTag,
              attributes: [],
            });

            if (!id) {
              console.log("failed to enroll");
            } else {
              await Router.push("/courses/purchased");
            }
          }}>
          Enroll Now
        </Button>
      ) : (
        <span className="error">Already enrolled</span>
      )}
      <style jsx>{`
        .error {
          width: 100%;
          color: rgb(255, 0, 0);
          text-align: center;
          padding: 15px 0;
        }
      `}</style>
    </>
  );
};

export default EnrollButton;
