import Link from "next/link";
import { FC, PropsWithChildren } from "react";

export type ButtonProps = {
  theme?: "primary" | "secondary";
  href: string;
};

const Button: FC<PropsWithChildren<ButtonProps>> = ({ theme = "primary", href, children }) => {
  return (
    <>
      <Link href={href}>
        <div className="Button">{children}</div>
      </Link>
      {/* language=CSS */}
      <style jsx>
        {`
          .Button {
            width: 100%;
            text-align: center;
            background-color: ${theme == "primary" ? "#94c1d0" : "rgb(45, 45, 45)"};
            color: white;
            font-weight: 600;
            font-size: 14px;
            text-transform: uppercase;
            padding: 16px 10px;
            cursor: pointer;
          }

          .Button:hover {
            background-color: ${theme == "primary" ? "#d9d8d6" : "rgb(45, 45, 45)"};
          }
        `}
      </style>
    </>
  );
};

export default Button;
