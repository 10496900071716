import { FC } from "react";

const Hero: FC = () => {
  return (
    <>
      <div className="hero">
        <div className="hero-block">
          <div className="hero-content"></div>
        </div>
      </div>
      {/* language=CSS */}
      <style jsx>
        {`
          .hero {
            overflow: hidden;
            width: 100vw;
            max-width: 100vw;
            padding-left: 0;
            padding-right: 0;
            margin-left: auto;
            margin-right: auto;
            box-sizing: border-box;
            display: block;
            left: 0;
            background-color: rgb(102, 102, 102);
            background-image: url("/static/hero.jpeg");
            background-size: cover;
          }

          .hero-block {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .hero-content {
            width: 595px;
          }

          h1 {
            color: rgb(255, 255, 255);
            font-size: 50px;
            font-weight: 300;
            text-align: center;
            padding-bottom: 30px;
            margin-bottom: 0px;
            line-height: 1em;
          }

          p {
            color: rgb(255, 255, 255);
            text-align: center;
            font-size: 18px;
            margin-top: 0;
            position: relative;
            width: 100%;
            border-style: solid;
            border-color: #94c1d0;
            border-width: 2px 0 0;
            padding-top: 30px;
          }

          .hero-description {
            padding: 20px 20px 0 20px;
          }

          @media print, (min-width: 768px) {
            .hero {
              height: 500px;
            }
          }
        `}
      </style>
    </>
  );
};

export default Hero;
