import Product from "@api/types/product";
import React, { useContext } from "react";

import EnrollButton from "@components/EnrollButton";
import LinkButton from "@components/LinkButton";
import UserContext from "@components/UserContext";

const ProductCourseCard: React.FC<{ product: Product }> = ({ product }) => {
  const { customer, token } = useContext(UserContext);

  return (
    <>
      <div className="ProductCard">
        <img src={product.thumbnail.src} alt={product.title} />
        <div className="title">{product.title}</div>
        <div className="sku">SKU: {product.mpn}</div>
        <div className="description" dangerouslySetInnerHTML={{ __html: product.longDescription }} />
        <div className="actions">
          <div className="buttons">
            {product.course && product.course.hasCoursePackages ? (
              !customer?.guest ? (
                <EnrollButton token={token} course={product.course} />
              ) : (
                <LinkButton href="/login">Sign in to enroll</LinkButton>
              )
            ) : (
              <LinkButton href="/login">Sign in to enroll</LinkButton>
            )}
            <LinkButton href={`/course/id/${encodeURIComponent(product.mpn)}`} theme="secondary">
              Course Details
            </LinkButton>
          </div>
        </div>
      </div>
      {/* language=CSS */}
      <style jsx>
        {`
          .ProductCard {
            position: relative;
            padding-bottom: 120px;
          }

          .ProductCard img {
            width: 100%;
            max-width: 100%;
          }

          .title {
            margin: 16px 0;
            font-size: 24px;
            font-weight: 500;
            color: rgb(0, 102, 204);
          }

          .sku {
            font-weight: bold;
            margin: 15px 0;
          }

          .actions {
            position: absolute;
            bottom: 0;
            width: 100%;
          }

          .buttons {
            display: flex;
          }

          .description {
            line-height: 26px;
            color: rgb(51, 51, 51);
          }

          .price {
            margin: 0 0 15px 0;
          }

          .price-value {
            color: #7e7e7e;
            font-size: 24px;
          }

          .error {
            width: 100%;
            color: rgb(255, 0, 0);
            text-align: center;
            padding: 15px 0;
          }
        `}
      </style>
    </>
  );
};

export default ProductCourseCard;
