import { GRAPHQL_ENDPOINT, SITE } from "@api/constants";
import enrollIntoCourseMutation from "@api/mutations/enroll-into-course";
import CourseEnrollmentInput from "@api/types/course-enrollment-input";

type MutationResult = {
  data: {
    enrollIntoCourse: {
      id: string;
      email: string;
      customerId: string;
    }[];
  };
};

const enrollIntoCourse = async (
  token: string,
  input: CourseEnrollmentInput
): Promise<
  {
    id: string;
    email: string;
    customerId: string;
  }[]
> => {
  const body = {
    query: enrollIntoCourseMutation,
    variables: {
      input,
    },
  };

  const response = await fetch(GRAPHQL_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CORE-SITE": SITE,
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  });
  const responseBody = (await response.json()) as MutationResult;
  return responseBody?.data?.enrollIntoCourse;
};

export default enrollIntoCourse;
